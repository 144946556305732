const SectionHead = ({ title }) => {
  return (
    <div className='section__head'>
      {/* <span></span> */}
      <h2>{title}</h2>
    </div>
  );
};

export default SectionHead;
