import React, { useEffect, useState } from 'react';
// import './works.scss';
import { workList, works } from '../data';
import WorkList from './WorkList';
import SectionHead from './SectionHead';
import Card from './Card';
import Popup from './Popup';

export default function Works() {
  const [selected, setSelected] = useState('movies');
  const [data, setData] = useState([]);
  useEffect(() => {
    setData(works.filter((i) => i.value === selected));
  }, [selected]);

  return (
    <section className='works'>
      <div className='container works__container'>
        <div className='works__head'>
          <SectionHead title='Works' />
        </div>
        <div className='works__wrapper'>
          <div className='works__title'>
            {workList.map((item) => (
              <WorkList
                title={item.title}
                active={selected === item.id}
                setSelected={setSelected}
                id={item.id}
                key={item.id}
              />
            ))}
          </div>
          <div className='works__work'>
            {data.map((d) => (
              <Card className='item' key={d.id}>
                <img src={d.img} alt={d.id} />
                <h4>{d.text}</h4>
                <Popup url={d.url} title={d.text} />
              </Card>
            ))}
          </div>
        </div>
      </div>
    </section>
  );
}
