import { useState } from 'react';
import Modal from 'react-modal';
import ReactPlayer from 'react-player';
Modal.setAppElement(`#root`);

function Popup({ url, title }) {
  const customStyles = {
    content: {
      //   top: '50%',
      //   left: '50%',
      //   right: 'auto',
      //   bottom: 'auto',
      //   marginRight: '-50%',
      //   transform: 'translate(-50%, -50%)',
    },
  };

  //   let subtitle;
  const [modalIsOpen, setIsOpen] = useState(false);

  function openModal({ url }) {
    setIsOpen(true);
  }

  function afterOpenModal() {
    // references are now sync'd and can be accessed.
    // subtitle.style.color = '#f00';
  }

  function closeModal() {
    setIsOpen(false);
  }

  return (
    <div className='popup'>
      <button className='btn' onClick={openModal}>
        Play Trailer
      </button>
      <Modal
        isOpen={modalIsOpen}
        onAfterOpen={afterOpenModal}
        onRequestClose={closeModal}
        style={customStyles}
        // contentLabel='Example Modal'
        className='modal'
      >
        {/* <h2 ref={(_subtitle) => (subtitle = _subtitle)}>{title}</h2> */}
        <button className='btn' onClick={closeModal}>
          Close
        </button>
        <div className='player-wrapper'>
          <ReactPlayer
            url={url}
            className='react-player'
            playing
            width='100%'
            height='100%'
            controls={false}
          />
        </div>
        {/* <form>
          <input />
          <button>tab navigation</button>
          <button>stays</button>
          <button>inside</button>
          <button>the modal</button>
        </form> */}
      </Modal>
    </div>
  );
}

export default Popup;
