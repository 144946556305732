import React from 'react';
import './works.css';
import Works from '../../components/Works';
import Header from '../../components/Header';
import ServiceImg from './../../img/services.jpg';

export default function WorksPage() {
  return (
    <div className='works'>
      <Header title='Our Works' image={ServiceImg}>
        Quality, correct and proffesional. Highest quality and on time!
      </Header>
      <Works />
    </div>
  );
}
