import './about.css';
import Header from '../../components/Header';
import AboutImg from './../../img/sound-waves-dynamic-effect.jpg';
import storyImg from './../../img/audio.jpeg';

const About = () => {
  return (
    <>
      <Header title='Welcome ' image={AboutImg}>
        More about Film Sound Albania!
      </Header>
      <section className='about__story'>
        <div className='about__story-container'>
          <div className='about__section-image-l'>
            <img src={storyImg} alt='story img' />
          </div>
          <div className='about__section-content'>
            <h1>Our Story</h1>
            <p>
              Location sound recording for film and TV in Albania, Film Sound
              Albania is your trusted partner.
            </p>
            <p>
              With our professionalism, state-of-the-art equipment,
              location-specific expertise, and collaborative approach, we ensure
              that your production’s audio is captured flawlessly, delivering an
              immersive and captivating experience for your audience.
            </p>
            <p>
              Our work consists of highly skilled location sound professionals
              with years of experience in the industry. We have worked on a wide
              range of film and TV productions, from intimate dramas to
              large-scale action films.
            </p>
          </div>
        </div>
      </section>

      <section className='about__vision'>
        <div className='about__vision-container'>
          <div className='about__section-content'>
            <h1>Our Vision</h1>
            <p>
              Location sound recording for film and TV in Albania, Film Sound
              Albania is your trusted partner.
            </p>
            <p>
              With our professionalism, state-of-the-art equipment,
              location-specific expertise, and collaborative approach, we ensure
              that your production’s audio is captured flawlessly, delivering an
              immersive and captivating experience for your audience.
            </p>
            <p>
              Our work consists of highly skilled location sound professionals
              with years of experience in the industry. We have worked on a wide
              range of film and TV productions, from intimate dramas to
              large-scale action films.
            </p>
          </div>
          <div className='about__section-image-r'>
            <img src={storyImg} alt='story img' />
          </div>
        </div>
      </section>

      <section className='about__mission'>
        <div className='about__mission-container'>
          <div className='about__section-image-l'>
            <img src={storyImg} alt='story img' />
          </div>
          <div className='about__section-content'>
            <h1>Our Mission</h1>
            <p>
              Location sound recording for film and TV in Albania, Film Sound
              Albania is your trusted partner.
            </p>
            <p>
              With our professionalism, state-of-the-art equipment,
              location-specific expertise, and collaborative approach, we ensure
              that your production’s audio is captured flawlessly, delivering an
              immersive and captivating experience for your audience.
            </p>
            <p>
              Our work consists of highly skilled location sound professionals
              with years of experience in the industry. We have worked on a wide
              range of film and TV productions, from intimate dramas to
              large-scale action films.
            </p>
          </div>
        </div>
      </section>
    </>
  );
};
export default About;
