import { Link, NavLink } from 'react-router-dom';
import './navbar.css';
import Logo from '../img/logo.png';
import { navLinks } from '../data';
import { TfiMenu } from 'react-icons/tfi';
import { MdOutlineClose } from 'react-icons/md';
import { useState } from 'react';

export default function Navbar() {
  const [isNavShowing, setIsNavShowing] = useState(false);

  return (
    <nav>
      <div className='container nav__container'>
        <Link to='/' className='logo' onClick={() => setIsNavShowing(false)}>
          <img src={Logo} alt='Logo' />
        </Link>
        <span> Make sound sound better!</span>

        <ul
          className={`nav__links ${isNavShowing ? 'show__nav' : 'hide__nav'}`}
        >
          {navLinks.map(({ name, path }, index) => {
            return (
              <li key={index}>
                <NavLink
                  to={path}
                  className={({ isActive }) => (isActive ? 'active-nav' : '')}
                  onClick={() => setIsNavShowing((prev) => !prev)}
                >
                  {name}
                </NavLink>
              </li>
            );
          })}
        </ul>
        <button
          className='nav__toggle-btn'
          onClick={() => setIsNavShowing((prev) => !prev)}
        >
          {isNavShowing ? <MdOutlineClose /> : <TfiMenu />}
        </button>
      </div>
    </nav>
  );
}
