export default function WorkList({ title, active, setSelected, id }) {
  return (
    <div
      className={active ? 'workList active' : 'workList'}
      onClick={() => {
        setSelected(id);
      }}
    >
      {title}
    </div>
  );
}
