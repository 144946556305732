import './services.css';
import Header from '../../components/Header';
import { services } from '../../data';
import ServiceImg from './../../img/services.jpg';

const Services = () => {
  return (
    <>
      {' '}
      <Header title='Our services' image={ServiceImg}>
        Quality, correct and proffesional. Highest quality and on time!
      </Header>
      {services.map((item) => (
        <div className='services__page' id={item.value}>
          <div className='services__page-container'>
            <div className='services__page-content'>
              {' '}
              <h2>{item.title}</h2>
              {item.text.map((pa) => (
                <p className='p__services'>{pa}</p>
              ))}
            </div>

            <div className='services__page-img'>
              <img src={item.img} alt={item.id} />
            </div>
          </div>
        </div>
      ))}
    </>
  );
};

export default Services;
