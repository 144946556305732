export const services = [
  {
    title: 'Voice Over',
    value: 'voice',
    id: 1,
    shortText:
      'Audio post-production voice-over services for a variety of projects including image films, promotional videos...',
    text: [
      'Audio post-production voice-over services for a variety of projects including image films, promotional videos, advertising campaigns, and documentaries.',
      ' Our team specializes in providing top-quality voice-over and dubbing services tailored to your specific needs in Albania. ',
      'Our talented voice actors bring depth and emotion to every recording, whether you need a warm narration, character voices, or a compelling commercial voice-over. ',
      'We work with a diverse professionals who excel in delivering captivating performances across various genres and languages. ',
    ],
    img: '../img/services/vov.jpeg',
    path: '/services',
  },
  {
    title: 'Sound Post Production',
    value: 'sound',
    id: 2,
    shortText:
      'We provide professional Audio mix services for various forms of media, including film and television...',
    text: [
      'We provide professional Audio mix services for various forms of media, including film and television.',
      ' Our expert team specializes in post-production mixing and mastering to ensure a well-balanced and authentic sound for projects such as movies, promotional videos, documentaries, and advertising campaigns.',
      ' Led by skilled audio engineers with expertise in film and TV sound, our team meticulously crafts balanced mixes that highlight the details of your sound design, elevate storytelling, and engage your audience.',
      ' We focus on achieving optimal dialogue clarity and balance, addressing issues such as background noise and inconsistent levels with advanced techniques to enhance speech intelligibility.',
      ' Film Sound Albania is your trusted partner for professional audio mixing services in Albania.',
    ],
    img: './img/services/postprod.jpeg',
    path: '/services',
  },
  {
    title: 'On Set Recording',
    value: 'set',
    id: 3,
    shortText:
      'Boom operation, wireless microphone setup, sound mixing, sound design, and on-location troubleshooting...',
    text: [
      'Our services include boom operation, wireless microphone setup, sound mixing, sound design, and on-location troubleshooting.',
      ' We work closely with directors, producers, and other members of the production team to ensure that the sound quality meets your standards and enhances the overall viewing experience.',
      ' Whether you are filming in a noisy urban environment or a remote natural setting, we have the expertise and equipment to capture high-quality audio in any location. We understand the importance of sound in creating a fully immersive and engaging viewing experience, and we are committed to delivering exceptional results for every project we work on.',
      ' Contact Film Sound Albania today to discuss your location sound recording needs and how we can help bring your vision to life. We are passionate about sound and dedicated to providing the best possible audio for your film or TV production. Let us take care of the sound, so you can focus on creating a visually stunning and compelling story.',
    ],
    img: './img/services/locsound.jpeg',
    path: '/services',
  },
  // {
  //   title: 'Translation',
  //   value: 'translation',
  //   id: 4,
  //   text: 'Translation and ....',
  //   img: './img/default.png',
  //   path: '/services',
  // },
  // {
  //   title: 'Subtitling',
  //   value: 'subtitling',
  //   id: 5,
  //   text: 'Subtitling ......',
  //   img: './img/default.png',
  //   path: '/services',
  // },
];
export const servicesList = [
  // { title: 'Dubbing', id: 'dubbing' },
  { title: 'Voice Over', id: 'voice' },
  { title: 'Sound Post Production', id: 'sound' },
  { title: 'On Set Recording', id: 'set' },
  { title: 'Translation', id: 'translation' },
  { title: 'Subtitling', id: 'subtitling' },
];
export const works = [
  {
    title: 'Movies',
    value: 'movies',
    id: 1,
    text: 'Ti mund te me quash xhon',
    img: ' img/movies/john-poster.jpg',
    url: 'https://www.youtube.com/watch?v=Me1EQTy7uUA',
  },
  {
    title: 'Movies',
    value: 'movies',
    id: 2,
    text: 'THE CONFESSION OF A MURDERER',
    img: ' img/movies/theconfessionmurderer.jpg',
    url: 'https://vimeo.com/516176903',
  },
  {
    title: 'Movies',
    value: 'movies',
    id: 3,
    text: 'Foreigner',
    img: ' img/movies/e-huaj-poster.jpg',
    url: 'https://vimeo.com/244018268',
  },
  {
    title: 'Movies',
    value: 'movies',
    id: 4,
    text: 'Gjergj',
    img: ' img/movies/gjergj.jpg',
    url: 'https://vimeo.com/516177954',
  },
  {
    title: 'Movies',
    value: 'movies',
    id: 5,
    text: 'Out of a Dream',
    img: ' img/movies/ardhurngaendrrat.jpg',
    url: 'https://vimeo.com/250602242',
  },
  {
    title: 'Movies',
    value: 'movies',
    id: 6,
    text: 'Ego',
    img: ' img/movies/Ego.jpg',
    url: 'https://www.youtube.com/watch?v=zxRRn8odG9E',
  },
  {
    title: 'Movies',
    value: 'movies',
    id: 7,
    text: 'Ketu vallezohet me hijet',
    img: ' img/movies/Ketu Vallzohet me hijet.jpg',
    url: 'https://www.youtube.com/watch?v=h-uu9Hxe3fg',
  },
  {
    title: 'Movies',
    value: 'movies',
    id: 8,
    text: 'Two Lions to Venice',
    img: ' img/movies/Two Lions In to Venice.jpg',
    url: 'https://www.youtube.com/watch?v=uBPx_5TnHZY',
  },
  {
    title: 'Movies',
    value: 'movies',
    id: 9,
    text: 'Falco',
    img: ' img/movies/falco.jpg',
    url: 'https://www.youtube.com/watch?v=Tma-oyiqMIc',
  },
  {
    title: 'Movies',
    value: 'movies',
    id: 10,
    text: 'Sophia',
    img: ' img/movies/sophia.jpg',
    url: 'https://www.youtube.com/watch?v=KC4ZrdfLyw8',
  },
  {
    title: 'Movies',
    value: 'movies',
    id: 11,
    text: 'Profesionisti',
    img: ' img/movies/Profesionisti.jpg',
    url: 'https://www.youtube.com/watch?v=TOqKFSPzppA',
  },
  {
    title: 'Movies',
    value: 'movies',
    id: 12,
    text: 'Gjyshja',
    img: ' img/movies/gjyshja.jpg',
    url: 'https://www.youtube.com/watch?v=4h_y08cM8dE',
  },
  {
    title: 'Documentar',
    value: 'documentar',
    id: 13,
    text: 'Documentar 22222.....',
    img: 'img/rec.png',
    url: '',
  },
  {
    title: 'Documentar',
    value: 'documentar',
    id: 14,
    text: 'Documentar .....',
    img: 'img/rec.png',
    url: '',
  },
  {
    title: 'Maya the bee. The honey games',
    value: 'animated',
    id: 15,
    text: 'Maya the bee.The honey games',
    img: 'img/animated/maya.jpg',
    url: 'trailers/animated/mayathebee.MP4',
  },
  {
    title: 'Marney the spy cat',
    value: 'animated',
    id: 16,
    text: 'Marney the spy cat',
    img: 'img/animated//marney.jpg',
    url: 'trailers/animated/marney.MP4',
  },
  {
    title: 'Terra Willy',
    value: 'animated',
    id: 17,
    text: 'Terra Willy',
    img: 'img/animated//terraWilly.jpg',
    url: 'trailers/animated/terrawilly.MP4',
  },
];
export const workList = [
  { title: 'Movies', id: 'movies' },
  { title: 'Documentar', id: 'documentar' },
  { title: 'Animated Movies', id: 'animated' },
];
export const navLinks = [
  { name: 'Home', path: '/' },
  { name: 'About', path: '/about' },
  { name: 'Services', path: '/services' },
  { name: 'Works', path: '/works' },
  { name: 'Contact', path: '/contact' },
];
