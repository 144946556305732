import React from 'react';
import { services } from '../data';
import SectionHead from './SectionHead';
import Card from './Card';
import { Link } from 'react-router-dom';
import { AiFillCaretRight } from 'react-icons/ai';

const Services = () => {
  return (
    <section className='services'>
      <div className='container services__container'>
        <div className='services__head'>
          <SectionHead title='Services' />
        </div>
        <div className='services__wrapper'>
          {services.map((item) => (
            <Card className='services__services' key={item.id}>
              <img src={item.img} alt={item.id} />
              <h4>{item.title}</h4>
              <small>{item.shortText}</small>
              <Link to={item.path} className='btn sm'>
                Learn more <AiFillCaretRight />
              </Link>
            </Card>
          ))}
        </div>
      </div>
    </section>
  );
};

export default Services;
