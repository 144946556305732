import { Link } from 'react-router-dom';
import '../pages/home/home.css';
import Image from '../img/Microphone.png';

const MainHeader = () => {
  return (
    <header className='main__header'>
      <div className='container main__header-container'>
        <div className='main__header-left'>
          <h4>Welcome to</h4>
          <h1>Film Sound Albania</h1>
          <p>
            Our studio offer a full range of creative and technical expertise. A
            full range of audio background services for large and small
            businesses of the highest order.
          </p>
          <Link to={'/about'} className='btn lg'>
            Learn more
          </Link>
        </div>
        <div className='main__header-right'>
          <div className='main__header-image'>
            <img src={Image} alt='img' />
          </div>
        </div>
      </div>
    </header>
  );
};

export default MainHeader;
