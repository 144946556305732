import { Link } from 'react-router-dom';
import './notFound.css';

export default function NotFound() {
  return (
    <section>
      <div className='container notfound__container'>
        <h2>Page Not Found</h2>
        <Link to='' className='btn'>
          Home
        </Link>
      </div>
    </section>
  );
}
