import React from 'react';
import './contact.css';
import Header from '../../components/Header';
import { MdEmail } from 'react-icons/md';
import { BsTelephone } from 'react-icons/bs';
import { IoLogoWhatsapp } from 'react-icons/io';

import ContactImg from './../../img/contact.png';

const Contact = () => {
  return (
    <>
      <Header title='Contact Us' image={ContactImg}>
        for information or collaboration, cooperation with Film Sound Albania!
      </Header>
      <section className='contact'>
        <div className='container contact__container'>
          <div className='contact__wrapper'>
            <a
              href='mailto:contact@filmsoundalbania.org'
              target='_blank'
              rel='noreferrer noopener'
            >
              <MdEmail />
            </a>
            <p className='p__contact'>contact@filmsoundalbania.org</p>
            <a
              href='mailto:info@filmsoundalbania.org'
              target='_blank'
              rel='noreferrer noopener'
            >
              <MdEmail />
            </a>
            <p className='p__contact'>info@filmsoundalbania.org</p>
            <a
              href='https://wa.me/+355675030011'
              target='_blank'
              rel='noreferrer noopener'
            >
              <IoLogoWhatsapp />
            </a>
            <p className='p__contact'>
              <BsTelephone /> (+355) 67 503 0011
            </p>
          </div>
        </div>
      </section>
    </>
  );
};

export default Contact;
