import { Link } from 'react-router-dom';
import Logo from '../img/logo.png';
import { FaLinkedin } from 'react-icons/fa';
import { FaFacebookF } from 'react-icons/fa';
import { AiOutlineTwitter } from 'react-icons/ai';
import { AiFillInstagram } from 'react-icons/ai';

const Footer = () => {
  return (
    <footer>
      <div className='container footer__container'>
        <article>
          <Link to='/' className='logo'>
            <img src={Logo} alt='Footer logo' />
          </Link>
          <p>Film Sound Albania. Make sound, sound better!</p>
          <div className='footer__socials'>
            <a
              href='https://linkedin.com/'
              target='_blank'
              rel='noreferrer noopener'
            >
              <FaLinkedin />
            </a>
            <a
              href='https://facebook.com/'
              target='_blank'
              rel='noreferrer noopener'
            >
              <FaFacebookF />
            </a>
            <a
              href='https://twitter.com/'
              target='_blank'
              rel='noreferrer noopener'
            >
              <AiOutlineTwitter />
            </a>
            <a
              href='https://instagram.com/'
              target='_blank'
              rel='noreferrer noopener'
            >
              <AiFillInstagram />
            </a>
          </div>
        </article>
        <article>
          <h4>Permalinks</h4>
          <Link to='/about'>About</Link>
          <Link to='/services'>Services</Link>
          <Link to='/works'>Our Works</Link>
          <Link to='/contact'>Contact</Link>
        </article>
        {/* <article>
          <h4>Permalinks</h4>
          <Link to='/about'>About</Link>
          <Link to='/services'>Services</Link>
          <Link to='/works'>Our Works</Link>
          <Link to='/contact'>Contact</Link>
        </article> */}
        <article>
          <h4>Get In Touch</h4>
          <Link to='/contact'>Contact</Link>
          <Link to='/contact'>Info</Link>
        </article>
      </div>
      <div className='footer__copyright'>
        <small>
          All Rights Reserved &copy; {new Date().getFullYear()} Film Sound
          Albania
        </small>
      </div>
    </footer>
  );
};

export default Footer;
