import './home.css';
import MainHeader from '../../components/MainHeader';
import Services from '../../components/Services';
import Works from '../../components/Works';

const Home = () => {
  return (
    <>
      <MainHeader />
      <Services />
      <Works />
    </>
  );
};

export default Home;
